import React, { useEffect, useState } from 'react'

import { PencilSquareIcon, PlusCircleIcon } from '@heroicons/react/24/outline'
import { PaperAirplaneIcon } from '@heroicons/react/24/solid'
import { Chip, Tooltip } from '@mui/material'
import Stack from '@mui/material/Stack'
import TextField from '@mui/material/TextField'
import dayjs from 'dayjs'
import {
  NpsAssessor,
  NpsSubject,
  TableName,
  type NpsInsightDataQuery,
} from 'types/graphql'
import { useBoolean } from 'usehooks-ts'

import { useMutation } from '@redwoodjs/web'
import { toast } from '@redwoodjs/web/toast'

import Accordion from 'src/components/Library/Accordion/Accordion'
import Autocomplete from 'src/components/Library/Autocomplete/Autocomplete'
import Button from 'src/components/Library/Button'
import IconButton from 'src/components/Library/IconButton'
import { npsCampaignConfig } from 'src/components/Library/RecordSettingsModal/config'
import RecordSettingsModal from 'src/components/Library/RecordSettingsModal/RecordSettingsModal'
import SimpleForm from 'src/components/Library/SimpleForm/SimpleForm'
import UnderlinedValue from 'src/components/Library/UnderlinedValue/UnderlinedValue'
import Modal from 'src/components/Modal/Modal'
import NpsResultsCell from 'src/components/Nps/NpsSettings/NpsResultsCell'
import SpeedMeterIcon from 'src/lib/icons/SpeedMeterIcon/SpeedMeterIcon'
import {
  DELETE_NPS_CAMPAIGN,
  UPDATE_NPS_CAMPAIGN,
} from 'src/lib/queries/Settings/NPS/NpsCampaign'
import { CREATE_NPS_RESULT } from 'src/lib/queries/Settings/NPS/NpsResults'
import { CREATE_NPS_SUBJECT } from 'src/lib/queries/Settings/NPS/NpsSubject'
import { convertNumberToShortForm } from 'src/Util'

export const CampaignSummary = ({ campaign }) => {
  return (
    <div className={'mx-2 flex w-full items-center justify-end'}>
      <div>
        <Stack direction={'row'} spacing={2}>
          {/*  need to get a calculated value for this part*/}
          <Tooltip title="Surveys Sent">
            <Chip
              className={'w-17'}
              icon={
                <PaperAirplaneIcon className="h-5 w-5 -rotate-45 fill-gray-700" />
              }
              size={'small'}
              label={
                campaign.surveySentCount
                  ? convertNumberToShortForm(campaign.surveySentCount)
                  : 0
              }
            />
          </Tooltip>
          <Tooltip title={'NPS Score'}>
            <Chip
              className={'w-17'}
              icon={<SpeedMeterIcon className="h-6 w-6 fill-gray-700" />}
              size={'small'}
              label={campaign.scoreTotals.npsScore}
            />
          </Tooltip>
          <UnderlinedValue
            value={
              campaign.scoreTotals.detractorCount
                ? convertNumberToShortForm(campaign.scoreTotals.detractorCount)
                : 0
            }
            color={'red'}
          />
          <UnderlinedValue
            value={
              campaign.scoreTotals.neutralCount
                ? convertNumberToShortForm(campaign.scoreTotals.neutralCount)
                : 0
            }
            color={'yellow'}
          />
          <UnderlinedValue
            value={
              campaign.scoreTotals.promoterCount
                ? convertNumberToShortForm(campaign.scoreTotals.promoterCount)
                : 0
            }
            color={'green'}
          />
        </Stack>
      </div>
    </div>
  )
}

interface CampaignTitleProps {
  onEdit?: React.MouseEventHandler<HTMLButtonElement>
  campaign: NpsInsightDataQuery['npsCampaigns'][0]
}

export const CampaignTitle = ({ campaign, onEdit }: CampaignTitleProps) => {
  return (
    <div className={'flex w-full items-end'}>
      <Stack spacing={1}>
        <div className="flex items-center">
          <h1 className={'text-sm font-semibold capitalize leading-5'}>
            {campaign.name}
          </h1>
          {onEdit && (
            <Tooltip title={'Edit Campaign'}>
              <div>
                <IconButton
                  size={'small'}
                  className={'mx-2 transition-all hover:!bg-indigo-100'}
                  onClick={onEdit}
                >
                  <PencilSquareIcon className={'h-4 w-4 stroke-2'} />
                </IconButton>
              </div>
            </Tooltip>
          )}
        </div>
        <Stack direction={'row'} spacing={2}>
          <Chip
            className={
              '!border-1 !border-indigo-800 bg-indigo-200 normal-case text-indigo-800'
            }
            sx={{ border: '1px solid' }}
            size={'small'}
            variant="filled"
            color="primary"
            label={campaign.campaignType}
          />
          <div className="flex items-center">
            <p
              className={
                'flex text-xs font-medium uppercase leading-4 tracking-wider text-gray-400'
              }
            >
              Date:
            </p>
            <p
              className={
                'text-md font-large ml-2 leading-4 tracking-wider text-gray-600'
              }
            >
              {dayjs(campaign.campaignDate).format('DD/MM/YYYY')}
            </p>
          </div>
        </Stack>
      </Stack>
    </div>
  )
}

// TODO: Add types for the props
const NpsCampaignView = ({ npsCampaigns, subjects, assessors }) => {
  const [newSubject, setNewSubject] = useState(null)
  const [newAssessor, setNewAssessor] = useState(null)
  const [resultsMap, setResultsMap] = useState(
    new Map([
      ['Detractors', '0'],
      ['Passives', '0'],
      ['Promoters', '0'],
    ]),
  )
  const newResultModal = useBoolean(false)
  const [campaignId, setCampaignId] = useState(null)
  const [editCampaign, setEditCampaign] = useState(null)
  const recordModal = useBoolean(false)
  const [recordLoading, setRecordLoading] = useState(false)
  useEffect(() => {
    if (editCampaign) {
      recordModal.toggle()
    }
  }, [editCampaign])

  const [createNpsResult] = useMutation(CREATE_NPS_RESULT, {
    onCompleted: async () => {
      toast.success('Result Added', {
        duration: 2000,
        className: 'flex-column',
      })
    },
    refetchQueries: ['NpsResultsQuery'],
    onError: (error) => {
      toast.error(error.message, {
        duration: 5000,
        className: 'flex-column',
      })
    },
  })

  const [deleteNpsCampaign] = useMutation(DELETE_NPS_CAMPAIGN, {
    onCompleted: async () => {
      toast.success('Campaign Deleted', {
        duration: 2000,
        className: 'flex-column',
      })
    },
    onError: (error) => {
      toast.error(
        error.message + ' Please remove all results before deleting',
        {
          duration: 5000,
          className: 'flex-column',
        },
      )
    },
    refetchQueries: ['NpsCampaignList'],
  })

  const [updateNpsCampaign] = useMutation(UPDATE_NPS_CAMPAIGN, {
    onCompleted: async () => {
      toast.success('Campaign Updated', {
        duration: 2000,
        className: 'flex-column',
      })
    },
    onError: (error) => {
      toast(error.message, { duration: 5000, className: 'flex-column' })
    },
    refetchQueries: ['NpsCampaignList'],
  })

  const [createNpsSubject] = useMutation(CREATE_NPS_SUBJECT, {
    onCompleted: async () => {},
    onError: (error) => {
      toast.error(error.message, {
        duration: 5000,
        className: 'flex-column',
      })
    },
    refetchQueries: ['FindNpsMeasurementQuery', 'NpsResultsQuery'],
  })

  const updateMap = (key, value) => {
    setResultsMap(new Map(resultsMap.set(key, value)))
  }

  const handleNewResult = () => {
    if (!newSubject) {
      toast.error('Please select a Performer')
      return
    }
    if (!newAssessor) {
      toast.error('Please select a Assessor')
      return
    }
    const results = [...resultsMap.keys()].map((key) => {
      if (isNaN(parseInt(resultsMap.get(key))) || resultsMap.get(key) === '') {
        toast.error(`Please enter a Number for ${key}`)
        return undefined
      } else {
        return parseInt(resultsMap.get(key))
      }
    })

    if (results.includes(undefined)) {
      return
    }

    setRecordLoading(true)

    if (newSubject?.__typename === 'Membership') {
      //create a subject
      createNpsSubject({
        variables: {
          input: {
            membershipId: newSubject.memberId,
          },
        },
      }).then(({ data }) => {
        createNpsResult({
          variables: {
            input: {
              campaignId: campaignId,
              subjectId: data.createNpsSubject.id,
              assessorId: newAssessor?.id,
              detractorCount: results[0],
              neutralCount: results[1],
              promoterCount: results[2],
            },
          },
        }).then(() => {
          newResultModal.toggle()
          setRecordLoading(false)
        })
      })
      //create a result for  subject
    } else {
      //create a result
      createNpsResult({
        variables: {
          input: {
            campaignId: campaignId,
            subjectId: newSubject.id,
            assessorId: newAssessor?.id,
            detractorCount: results[0],
            neutralCount: results[1],
            promoterCount: results[2],
          },
        },
      }).then(() => {
        newResultModal.toggle()
        setRecordLoading(false)
      })
    }

    setResultsMap(
      new Map([
        ['Detractors', '0'],
        ['Passives', '0'],
        ['Promoters', '0'],
      ]),
    )
  }

  return (
    <>
      {recordModal.value && editCampaign && (
        <RecordSettingsModal
          record={editCampaign}
          modalVisible={recordModal}
          config={npsCampaignConfig}
          onDelete={async (record) => {
            await deleteNpsCampaign({
              variables: {
                id: record.id,
              },
            })
          }}
          onSave={async (record) => {
            const nameInvalid = record.name === ''
            if (nameInvalid) {
              toast.error('Campaign name is required')
              return
            }
            await updateNpsCampaign({
              variables: {
                id: record.id,
                input: {
                  name: record.name,
                  campaignDate: record.customFields[0]?.value,
                  surveySentCount: parseInt(
                    record.customFields[1]?.value as string,
                  ),
                },
              },
            })
          }}
        />
      )}
      {newResultModal.value && (
        <Modal
          onClose={newResultModal.toggle}
          open={newResultModal.value}
          footerVisible
          dialogClassName={'!p-0 max-w-[720px]'}
          title={'Add Result'}
          onConfirm={handleNewResult}
          onCancel={newResultModal.toggle}
          loading={recordLoading}
        >
          <div className={'mb-2 flex w-full items-center justify-center'}>
            <div className={'w-1/2'}>
              <Stack spacing={2}>
                <Autocomplete
                  getOptionLabel={(option: NpsSubject) =>
                    option?.name || option?.membership?.user.name
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Performer"
                      data-testid="nps-performer-input"
                    />
                  )}
                  options={subjects}
                  onChange={(_, value) => {
                    setNewSubject(value)
                  }}
                />
                <Autocomplete
                  getOptionLabel={(option: NpsAssessor) => option?.name}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Assessor"
                      data-testid="nps-assessor-input"
                    />
                  )}
                  options={assessors}
                  onChange={(_, value) => {
                    setNewAssessor(value)
                  }}
                />
                <SimpleForm records={resultsMap} handleChange={updateMap} />
              </Stack>
            </div>
          </div>
        </Modal>
      )}

      <Stack spacing={1} className={'my-2'}>
        {npsCampaigns.map((campaign) => (
          <Accordion
            key={campaign.id}
            title={
              <CampaignTitle
                campaign={campaign}
                onEdit={(event) => {
                  event.stopPropagation()
                  setEditCampaign({
                    id: campaign.id,
                    type: campaign.__typename as TableName,
                    name: campaign.name,
                    customFields: [
                      {
                        name: 'Start Date',
                        value: campaign.campaignDate,
                        type: 'date',
                      },
                      {
                        name: 'Surveys Sent',
                        value: campaign.surveySentCount,
                        type: 'number',
                      },
                    ],
                  })
                }}
              />
            }
            summary={<CampaignSummary campaign={campaign} />}
            TransitionProps={{ unmountOnExit: true }}
          >
            {/* need to get the results of the campaign and group them by subject */}
            <NpsResultsCell
              id={campaign.id}
              subjects={subjects}
              assessors={assessors}
              createNpsSubject={createNpsSubject}
            />
            <Button
              variant={'text'}
              startIcon={<PlusCircleIcon className={'h-6 w-6 stroke-2'} />}
              fullWidth={false}
              onClick={() => {
                newResultModal.toggle()
                setNewSubject(null)
                setNewAssessor(null)
                setCampaignId(campaign.id)
              }}
              buttonDataTestId="nps-add-a-new-result-button"
            >
              Add A New Result
            </Button>
          </Accordion>
        ))}
      </Stack>
    </>
  )
}

export default NpsCampaignView
