export const CREATE_NPS_CAMPAIGN = gql`
  mutation CreateNpsCampaign($input: CreateNpsCampaignInput!) {
    createNpsCampaign(input: $input) {
      id
    }
  }
`
export const UPDATE_NPS_CAMPAIGN = gql`
  mutation UpdateNpsCampaign($id: Int!, $input: UpdateNpsCampaignInput!) {
    updateNpsCampaign(id: $id, input: $input) {
      id
    }
  }
`
export const DELETE_NPS_CAMPAIGN = gql`
  mutation DeleteNpsCampaign($id: Int!) {
    deleteNpsCampaign(id: $id) {
      id
    }
  }
`
