export const DELETE_NPS_RESULT = gql`
  mutation DeleteNpsResult($id: Int!) {
    deleteNpsResult(id: $id) {
      id
    }
  }
`

export const UPDATE_NPS_RESULT = gql`
  mutation UpdateNpsResult($id: Int!, $input: UpdateNpsResultInput!) {
    updateNpsResult(id: $id, input: $input) {
      id
    }
  }
`

export const CREATE_NPS_RESULT = gql`
  mutation CreateNpsResult($input: CreateNpsResultInput!) {
    createNpsResult(input: $input) {
      id
    }
  }
`
