// Imports
import { FC } from 'react'

// Icon Props
interface SpeedMeterIconProps {
  fillColour?: string
  className?: string
}

const SpeedMeterIcon: FC<SpeedMeterIconProps> = ({ className }) => {
  return (
    <svg
      className={`${className} mx-auto stroke-0 fill-inherit`}
      viewBox="0 0 23 12"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      stroke="currentColor"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.9945 11.1572C22.9945 11.1681 23 11.1776 23 11.1883C23 11.6368 22.638 12 22.1897 12C21.7427 12 21.3793 11.6368 21.3793 11.1883H21.378C20.9662 6.28598 16.8621 2.43348 11.8518 2.43348C11.5857 2.43348 11.3225 2.44418 11.0632 2.46583C10.2204 2.53601 9.53574 2.70616 9.06451 2.84932C8.57826 2.96688 8.10563 2.64007 8.03406 2.19303C7.97064 1.79465 8.24064 1.38009 8.67291 1.27197C9.68031 0.972167 10.7472 0.81284 11.8518 0.81284C17.7453 0.81284 22.5622 5.3748 22.9946 11.1575L22.9945 11.1572ZM3.02909 5.14832C3.33437 4.89309 3.77732 4.89042 4.07434 5.13215C4.39312 5.39272 4.46738 5.86942 4.23239 6.22591C3.53421 7.14558 2.99944 8.19631 2.66854 9.33477C2.54971 9.74934 2.3336 10.353 2.32555 11.189C2.32555 11.6359 1.95683 12.0019 1.51119 11.9993C1.07091 11.9979 0.706299 11.6332 0.706299 11.189C0.750875 10.5542 0.850852 9.9344 0.999354 9.33337C1.46129 7.45088 2.33905 6.04787 3.02909 5.14832ZM2.87562 0.088825C6.27589 2.76669 9.6765 5.44339 13.0771 8.12116C13.8725 8.86939 13.9158 10.0794 13.2918 10.7924C12.5963 11.5879 11.1945 11.6231 10.3829 10.702C7.72517 7.32975 5.06879 3.95784 2.41254 0.5856C2.27066 0.445122 2.27614 0.215479 2.41254 0.0886488C2.53672 -0.0275031 2.73933 -0.0315786 2.87575 0.0886488L2.87562 0.088825Z"
      />
    </svg>
  )
}

export default SpeedMeterIcon
