export const DELETE_NPS_SUBJECT = gql`
  mutation DeleteNpsSubject($id: Int!) {
    deleteNpsSubject(id: $id) {
      id
    }
  }
`

export const UPDATE_NPS_SUBJECT = gql`
  mutation UpdateNpsSubject($id: Int!, $input: UpdateNpsSubjectInput!) {
    updateNpsSubject(id: $id, input: $input) {
      id
    }
  }
`

export const CREATE_NPS_SUBJECT = gql`
  mutation CreateNpsSubject($input: CreateNpsSubjectInput!) {
    createNpsSubject(input: $input) {
      id
    }
  }
`
