import React from 'react'

import { Tooltip } from '@mui/material'

interface UnderlinedValueProps {
  value: string | number
  className?: string
  color?: string
  title?: string | number
}

const UnderlinedValue: React.FC<UnderlinedValueProps> = ({
  value,
  className,
  color,
  title,
}) => {
  const colorClass = color ? `border-${color}-500` : 'text-gray-500'
  return (
    <Tooltip title={title} placement={'top'} arrow>
      <div
        className={`${className} flex w-8 border-b-2 inline-block px-0.5 justify-center ${colorClass}`}
      >
        <span className={'text-sm leading-4 font-medium'}>{value}</span>
      </div>
    </Tooltip>
  )
}

export default UnderlinedValue
