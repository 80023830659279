import React from 'react'

import Stack from '@mui/material/Stack'
import { calculateNpsScore } from 'api/src/common/utils'
import type {
  NpsInsightDataQuery,
  NpsResultsQuery,
  NpsResultsQueryVariables,
  NpsSubject,
} from 'types/graphql'

import type { CellFailureProps, CellSuccessProps } from '@redwoodjs/web'

import { default as LoadingSpinner } from 'src/components/Library/Loading'
import NpsResult from 'src/components/Nps/NpsSettings/NpsResult/NpsResult'

export const QUERY = gql`
  query NpsResultsQuery($id: Int!) {
    results: npsResultsByCampaign(id: $id) {
      subject {
        id
        name
        membership {
          user {
            id
            name
            avatarUrl
          }
        }
      }
      results {
        id
        campaignId
        clientId
        promoterCount
        detractorCount
        neutralCount
        score
        assessor {
          id
          name
        }
        subjectId
      }
    }
  }
`

export const Loading = () => <LoadingSpinner />

export const Empty = () => <></>

export const Failure = ({ error }: CellFailureProps) => (
  <div style={{ color: 'red' }}>Error: {error?.message}</div>
)

interface ResultsCellProps
  extends CellSuccessProps<NpsResultsQuery, NpsResultsQueryVariables> {
  readOnly?: boolean
  subjects: NpsInsightDataQuery['subjects']
  assessors: NpsInsightDataQuery['assessors']
  createNpsSubject: (options: unknown) => unknown
}

const countProperty = <T extends Record<K, number>, K extends keyof T>(
  array: T[],
  propertyName: K,
) => {
  let total = 0

  for (const item of array) {
    total += item[propertyName]
  }

  return total
}

export const Success = ({
  results,
  subjects,
  assessors,
  createNpsSubject,
  readOnly = false,
}: ResultsCellProps) => {
  const tempResults = [...results]

  const sortedResults = tempResults.sort((a, b) => {
    const aNpsData = {
      promoterCount: countProperty(a.results, 'promoterCount'),
      detractorCount: countProperty(a.results, 'detractorCount'),
      neutralCount: countProperty(a.results, 'neutralCount'),
    }
    const bNpsData = {
      promoterCount: countProperty(b.results, 'promoterCount'),
      detractorCount: countProperty(b.results, 'detractorCount'),
      neutralCount: countProperty(b.results, 'neutralCount'),
    }

    const aNpsScore = calculateNpsScore(
      aNpsData.promoterCount,
      aNpsData.detractorCount,
      aNpsData.neutralCount,
    )
    const bNpsScore = calculateNpsScore(
      bNpsData.promoterCount,
      bNpsData.detractorCount,
      bNpsData.neutralCount,
    )

    return bNpsScore - aNpsScore
  })

  return (
    <>
      {sortedResults.map((resultGroup) => (
        <Stack key={resultGroup.subject.id} direction={'column'} spacing={2}>
          <NpsResult
            resultGroup={resultGroup}
            subjects={subjects}
            assessors={assessors}
            createNpsSubject={createNpsSubject}
            readOnly={readOnly}
          />
        </Stack>
      ))}
    </>
  )
}
